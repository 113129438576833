// import CastFour from "Screens/CastFour";
// import CastOne from "Screens/CastOne";
// import CastThree from "Screens/CastThree";
// import CastTwo from "Screens/CastTwo";
// import Home from "Screens/Home";
import RM from "Screens/RM";
import { AppContextProvider } from "context/AppContext";
import React, { useEffect } from "react";

import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";

function App() {
  const { pageNumber, id } = useParams();

  return (
    <>
      <BrowserRouter>
        {/* <AppContextProvider> */}
          <Routes>
            {/* <Route path="/" element={<Home />} /> */}
            {/*  <Route path="/:id/:pageNumber" element={<CastTwo />} />*/}
            {/* <Route path="/1/:pageNumber" element={<CastOne />} /> */}
            {/* <Route path="/2/:pageNumber" element={<CastThree />} /> */}
            {/* <Route path="/cast/:pageNumber" element={<CastFour />} /> */}
            <Route path="/:rm/:id" element={<RM />} />
          </Routes>
        {/* </AppContextProvider> */}
      </BrowserRouter>
    </>
  );
}
export default App;
