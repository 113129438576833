import Footer from "Components/Footer";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Styles from "Styles/Error.module.css";
import styles from "Styles/castTwo.module.css";
import Plyr from "plyr";
import EVoting from "Components/Contdown/EVoting";
// import Countdown from "Components/Contdown/Countdown";
import PageLoader from "Components/PageLoader/PageLoader";
import { useAppContext } from "context/AppContext";
import EntryModal from "Components/EntryModal/EntryModal";
import CustomHead from "header/CustomHead";
import Countdown from "Components/Contdown/Countdown";
import axios from "axios";
import { Container } from "react-bootstrap";
import TechRequirementsModal from "Components/Modals/TechRequirementsModal/TechRequirementsModal";
import OrganizationInformationModal from "Components/Modals/OrganizationInformationModal/OrganizationInformationModal";
import ConnectUsModal from "Components/Modals/ConnectUsModal/ConnectUsModal";
import LiveChatModal from "Components/Modals/LiveChatModal/LiveChatModal";
const RM = () => {
  const { id, rm } = useParams();
  const {
    //   setPage,
    //   loading,
    //   data,
    clearCacheData,
    handleSubmitQuestions,
    //   setLoading,
    //   setData,
    //   page,
  } = useAppContext();
  const [state, setState] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [page, setPage] = useState("");
  const navigate = useNavigate();
  const [videoShow, setVideoShow] = useState(false);
  const [dateOfMeeting, setDateOfMeeting] = useState("");
  const [timerOfMeeting, setTimerOfMeeting] = useState("");
  const [timerOfEMeeting, setTimerOfEMeeting] = useState("");
  const [manage, setManage] = useState("");
  const [formData, setFormData] = useState({
    vc_id: "",
    name: "",
    lf: "",
    question: "",
  });
  const [techRequirementModal, setTechRequirementModal] = useState(false);
  const [organizationInfoModal, setOrganizationInfoModal] = useState(false);
  const [connectUsModal, setConnectUsModal] = useState(false);
  const [liveChatModal, setLiveChatModal] = useState(false);
  useEffect(() => {
    setPage(id);
    setManage(rm);
    formData.rm = rm;
    formData.vc_id = id;
    // console.log("id", id);
    // console.log("rm", rm);
  }, [id, rm]);
  // useEffect(() => {
  //   if (data !== null) {
  //     const currentDate = new Date();
  //     const inputDate = new Date(data.date);
  //     const options = { year: "numeric", month: "long", day: "numeric" };
  //     setDateOfMeeting(inputDate.toLocaleDateString(undefined, options));

  //     const targetDate = new Date(`${data.date} ${data.time}`).getTime();
  //     setTimerOfMeeting(targetDate);

  //     if (currentDate > targetDate) {
  //       setVideoShow(true);
  //     }

  //     if (data.is_ended === "Yes" && data.ended_time) {
  //       const endedDateTime = new Date(data.ended_time);
  //       const fifteenMinutesFromEndedTime = new Date(endedDateTime.getTime() + data.eVotingtime * 60 * 1000);
  //       setTimerOfEMeeting(fifteenMinutesFromEndedTime);

  //       if (currentDate < targetDate || currentDate < fifteenMinutesFromEndedTime.getTime()) {
  //         setVideoShow(true);
  //       }
  //     }
  //   }
  // }, [data]);

  // console.log("setVideoShow", videoShow);
  // console.log("dateOfMeeting", dateOfMeeting);
  useEffect(() => {
    clearCacheData();
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Check if any field in formData is empty
    if (
      formData.vc_id === "" ||
      formData.name === "" ||
      formData.lf === "" ||
      formData.question === ""
    ) {
      // Display an error message or handle the validation error as needed
      alert("Please fill in all fields.");
    } else {
      // All fields are filled, submit the form
      handleSubmitQuestions(formData);
      alert("Thanks for question!!!!");
      setFormData({
        vc_id: "",
        name: "",
        lf: "",
        question: "",
      });
      setModalShow(false);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log({name, value});
    setFormData({ ...formData, [name]: value });
  };

  const fetchData = async (data) => {
    try {
      const response = await axios.get(
        `https://cowebcast.com/beta/api/apis/viewOrg.php?id=${page}`
      );
      if (response.status === 200) {
        const json = response.data?.[0];
        // console.log("Response data:", json);

        // console.log("new 1", json?.is_ended);
        // console.log("new 2", data?.is_ended);
        // console.log("new 3 final", typeof data?.is_ended ,typeof json?.is_ended);
        // console.log("new 4",data?.is_ended != json?.is_ended);

        if (
          // json?.is_ended == "Yes"
          // data?.is_ended == "No"  &&
          // json?.is_ended == "Yes" &&
          data?.is_ended != json?.is_ended
          // ||
          // data.ended_time !== json[0]?.ended_time
        ) {
          // console.log("new window reload");
          window.location.reload();
          // setData((prev) => ({ ...prev, ...json }));
          // navigate(0)
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchDataInit = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://cowebcast.com/beta/api/apis/viewOrg.php?id=${page}`,
          {}
        );
        if (response.status === 200) {
          const json = response.data;
          // console.log("res", json[0]);
          setData(json[0]);
        }
      } catch (e) {
        // console.log(e);
      } finally {
        setLoading(false);
      }
    };

    if (page !== "") {
      fetchDataInit();
    }
    
  }, [page]);

  useEffect(() => {
    // console.log("datain 2nd", data);
    if (data?.is_ended == "No") {
      const interval = setInterval(() => fetchData(data), 5000);
      return () => clearInterval(interval);
    }
   
  }, [data]);

  const [modalShow, setModalShow] = useState(false);
  const [time, setTime] = useState("");
  const [hasReloaded, setHasReloaded] = useState(false);
  const [hasEvotingReloaded, setHasEvotingReloaded] = useState(false);

  useEffect(() => {
    function convertToIST(dateTimeString) {
      // Combine date and time strings into a single datetime string
      // const dateTimeString = dateString + " " + timeString;

      // Create a Date object from the datetime string (this will assume local timezone)
      const localDate = new Date(dateTimeString);

      // Convert UTC time to IST (IST is UTC + 5:30)
      const options = { timeZone: "Asia/Kolkata", hour12: true };
      const istDate = new Date(localDate.toLocaleString("en-US", options));

      return istDate;
    }
    const checkConditions = () => {
      // window.location.reload();
      const currentDate = new Date();
      if (data && data !== null) {
        const inputDate = new Date(data.date);
        const options = { year: "numeric", month: "long", day: "numeric" };
        const formattedDate = inputDate.toLocaleDateString(undefined, options);
        setDateOfMeeting(formattedDate);

        const dateString = data.date; // "2023-11-02"
        const timeString = data.time; // "10:00:00 AM"
        const dateTimeString = dateString + " " + timeString;
        const targetDate = convertToIST(dateTimeString);
        setTimerOfMeeting(targetDate.getTime());

        // if (currentDate > targetDate) {
        //   console.log("currentDate > targetDate",currentDate > targetDate)
        //   setVideoShow(true);
        // }
        if (
          currentDate > new Date(data.date + " " + data.time) &&
          !hasReloaded
        ) {
          setHasReloaded(true);
          setVideoShow(true);
          // window.location.reload();
        }

        // if (currentDate > targetDate && currentDate > fifteenMinutesFromEndedTime.getTime()) {
        //   setHasEvotingReloaded(true)
        // }

        if (data.is_ended === "Yes" && data.ended_time && !hasEvotingReloaded) {
          // setHasEvotingReloaded(true)
          // setHasReloaded(false);
          // console.log("in data.is_ended === Yes");
          const endedDateTimeString = data.ended_time;
          const endedDateTime = new Date(endedDateTimeString);
          const fifteenMinutesFromEndedTime = new Date(
            endedDateTime.getTime() + parseInt(data.eVotingtime) * 60 * 1000
          );

          setTimerOfEMeeting(fifteenMinutesFromEndedTime);
          // console.log("hasReloaded", hasReloaded);
          if (
            currentDate > fifteenMinutesFromEndedTime.getTime() &&
            !hasEvotingReloaded
          ) {
            setHasEvotingReloaded(true);
          }
          if (
            currentDate < targetDate ||
            (currentDate < targetDate &&
              currentDate < fifteenMinutesFromEndedTime.getTime())
          ) {
            // setHasEvotingReloaded(false)
            setVideoShow(true);
          } else {
            setVideoShow(false);
          }
        }

        // console.log("hasEvotingReloaded", hasEvotingReloaded);
      }
    };

    const interval = setInterval(checkConditions, 1000);
    return () => clearInterval(interval); // Clear the interval on component unmount
  }, [data, hasReloaded, hasEvotingReloaded]);
  // }, [data]);

  useEffect(() => {
    if (data && data.time !== "") {
      setTime(formatTimeTo12Hour(data.time));
    }
  }, [data]);

  // const formatTimeTo12Hour = (time) => {
  //   const [hours, minutes] = time.split(":").map(Number);
  //   let period = "AM";
  //   let twelveHourFormatHours = hours;

  //   if (hours >= 12) {
  //     period = "PM";
  //     twelveHourFormatHours = hours === 12 ? 12 : hours - 12;
  //   }

  //   return `${twelveHourFormatHours}:${String(minutes).padStart(2, "0")} ${period}`;
  // };

  const formatTimeTo12Hour = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    let period = "AM";
    let twelveHourFormatHours = hours;

    if (hours >= 12) {
      period = "PM";
      twelveHourFormatHours = hours === 12 ? 12 : hours - 12;
    }

    return `${twelveHourFormatHours}:${String(minutes).padStart(
      2,
      "0"
    )} ${period}`;
  };

  useEffect(() => {
    console.log(data);
    // if (data && data !== null) {
    //   const player = new Plyr("#player");
    //   window.player = player;
    //   function on(selector, type, callback) {
    //     document
    //       .querySelector(selector)
    //       .addEventListener(type, callback, false);
    //   }
    //   on(".js-play", "click", () => {
    //     player.play();
    //   });

    //   on(".js-pause", "click", () => {
    //     player.pause();
    //   });

    //   on(".js-stop", "click", () => {
    //     player.stop();
    //   });

    //   on(".js-rewind", "click", () => {
    //     player.rewind();
    //   });

    //   on(".js-forward", "click", () => {
    //     player.forward();
    //   });
    //   return () => {
    //     if (player) {
    //       player.destroy();
    //     }
    //   };
    // }
  }, [data]);

  useEffect(() => {
    if (data && data.time !== "") {
      const [hours, minutes] = data.time.split(":").map(Number);

      // Convert the time to 12-hour format
      let period = "AM";
      let twelveHourFormatHours = hours;
      if (hours >= 12) {
        period = "PM";
        twelveHourFormatHours = hours === 12 ? 12 : hours - 12;
      }

      // Format the time in 12-hour format
      const formattedTime = `${twelveHourFormatHours}:${String(
        minutes
      ).padStart(2, "0")} ${period}`;
      // console.log("Time in 12-hour format:", formattedTime);
      setTime(formattedTime);
    }
  }, [data]);

  //   useEffect(() => {
  //   const vConsoleScript = document.createElement("script");
  //   vConsoleScript.src = "https://unpkg.com/vconsole@latest/dist/vconsole.min.js";
  //   document.body.appendChild(vConsoleScript);

  //   vConsoleScript.onload = function() {
  //     new window.VConsole();
  //   };

  //   // Cleanup script when component unmounts
  //   return () => {
  //     document.body.removeChild(vConsoleScript);
  //   };
  // }, []);
  // useEffect(() => {
  if (loading) {
    return <PageLoader />;
  }
  // }
  // }, [data,loading]);

  return (
    <React.Fragment>
      {data && data.is_ended ? (
        <>
          <div className={modalShow ? "d-none" : ""}>
            <div className={styles.second__wrapper}>
              <header>
                <div className="container-fluid">
                  <div className="row align-items-center gy-4">
                    <div className="col-6 col-md-6 col-lg-2">
                      {data.is_logo === "Yes" && (
                        <div className={styles.logo__img}>
                          <img
                            src={data.logo}
                            alt="logo"
                            className="img-fluid"
                          />
                          {/* <video src={data.logo}></video> */}
                        </div>
                      )}
                    </div>
                    <div className="col-6 col-md-6 col-lg-2 d-block d-lg-none">
                      {manage && (
                        <div className={`${styles.logo__img} ms-auto`}>
                          <img
                            src={`/assets/images/logos/${manage}.png`}
                            alt="logo"
                            className="img-fluid"
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-12 col-md-12 col-lg-8">
                      <div className={styles.details}>
                        <h4>{data.tagline}</h4>
                        <h2
                          style={{
                            color: data.p_color || "#2b3a8f",
                          }}
                        >
                          {data.org}
                        </h2>
                        <p>
                          Date: {dateOfMeeting}, {time} IST
                        </p>
                      </div>
                    </div>
                    <div className="col-6 col-md-6 col-lg-2 d-none d-lg-block">
                      <div className={`${styles.logo__img} ms-auto`}>
                        {manage && (
                          <div className={`${styles.logo__img} ms-auto`}>
                            <img
                              src={`/assets/images/logos/${manage}.png`}
                              alt="logo"
                              className="img-fluid"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <section className={`${styles.section} pt-2`}>
                <div className="container-fluid">
                  <div className={`${styles.section__title} mb-2`}>
                    <div className="row">
                      <div className="col-md-12 col-lg-9 mx-auto">
                        <p className="fw-bold m-0">
                          e-Voting Instructions: If you haven't cast your vote
                          during the remote e-Voting period, you can still do so
                          by going to eVoting portal. Once there, select the
                          'Active voting cycle' and click your EVEN to vote.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row gy-4">
                    <div
                      className={`col-md-12 col-lg-${
                        data.banner ? "6" : "9"
                      } mx-auto`}
                    >
                      {videoShow ? (
                        <div
                          className={`${
                            videoShow ? "d-flex" : "d-none"
                          } align-items-center justify-content-center video_wrapper`}
                          style={{ aspectRatio: data ? "16/9" : "auto" }}
                        >
                          <div
                            className="plyr__video-wrapper plyr__video-embed"
                            id="player"
                          >
                            <iframe
                              src={`https://www.youtube.com/embed/${data.stream}?autoplay=0&controls=0&disablekb=1&playsinline=1&cc_load_policy=0&cc_lang_pref=auto&widget_referrer=https%3A%2F%2Fcowebcast.com%2Fmeet%2FODA0%2FINE075K01013&rel=0&showinfo=0&iv_load_policy=3&modestbranding=1&customControls=true&noCookie=false&enablejsapi=1&origin=https%3A%2F%2Fcowebcast.com&widgetid=1`}
                              allowFullScreen
                              allowTransparency
                              allow="autoplay"
                              title="Video Player"
                            ></iframe>
                            <button
                              className="js-play"
                              style={{ display: "none" }}
                            >
                              Play
                            </button>
                            <button
                              className="js-pause"
                              style={{ display: "none" }}
                            >
                              Pause
                            </button>
                            <button
                              className="js-stop"
                              style={{ display: "none" }}
                            >
                              Stop
                            </button>
                            <button
                              className="js-rewind"
                              style={{ display: "none" }}
                            >
                              Rewind
                            </button>
                            <button
                              className="js-forward"
                              style={{ display: "none" }}
                            >
                              Forward
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="video_wrapper d-flex align-items-center justify-content-center"
                          style={{
                            background: "#fff",
                            border: `1px solid ${data.p_color}`,
                            aspectRatio: data ? "auto" : "16/9",
                          }}
                        >
                          <button
                            className="js-play"
                            style={{ display: "none" }}
                          >
                            Play
                          </button>
                          <button
                            className="js-pause"
                            style={{ display: "none" }}
                          >
                            Pause
                          </button>
                          <button
                            className="js-stop"
                            style={{ display: "none" }}
                          >
                            Stop
                          </button>
                          <button
                            className="js-rewind"
                            style={{ display: "none" }}
                          >
                            Rewind
                          </button>
                          <button
                            className="js-forward"
                            style={{ display: "none" }}
                          >
                            Forward
                          </button>
                          <div className="row w-100">
                            <div className="col-md-12 col-lg-12">
                              {data.is_ended === "Yes" && data.ended_time ? (
                                <>
                                  <div className="text-center">
                                    Meeting is concluded, Post Meeting eVoting
                                    Countdown...
                                  </div>
                                  <EVoting countdownDate={timerOfEMeeting} />
                                </>
                              ) : (
                                <>
                                  <div className="text-center">
                                    The Meeting will start in
                                  </div>
                                  <Countdown countdownDate={timerOfMeeting} />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {data.banner && (
                      <div className="col-md-12 col-lg-6">
                        <div className={styles.side_image}>
                          {data.m_type === 1 ? (
                            <img
                              src={data.banner}
                              alt="banner"
                              className="img-fluid"
                              style={{ border: `1px solid ${data.p_color}` }}
                            />
                          ) : (
                            <div
                              className="d-flex align-items-center justify-content-center video_wrapper"
                              style={{ aspectRatio: "auto" }}
                            >
                              <div
                                className="plyr__video-wrapper plyr__video-embed"
                                id="player"
                              >
                                <iframe
                                  src={data.banner_video} // Banner Image
                                  allowFullScreen
                                  allowTransparency
                                  allow="autoplay"
                                  title="Video Player"
                                ></iframe>
                                <button
                                  className="js-play"
                                  style={{ display: "none" }}
                                >
                                  Play
                                </button>
                                <button
                                  className="js-pause"
                                  style={{ display: "none" }}
                                >
                                  Pause
                                </button>
                                <button
                                  className="js-stop"
                                  style={{ display: "none" }}
                                >
                                  Stop
                                </button>
                                <button
                                  className="js-rewind"
                                  style={{ display: "none" }}
                                >
                                  Rewind
                                </button>
                                <button
                                  className="js-forward"
                                  style={{ display: "none" }}
                                >
                                  Forward
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>
              <section className={styles.section}>
                <div className="container-fluid">
                  <div className="row justify-content-center g-2">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                      <button
                        type="button"
                        className={`${styles.modal_toggler} btn`}
                        style={{
                          background: data.p_color || "",
                          borderColor: data.p_color || "",
                        }}
                        onClick={() => setTechRequirementModal(true)}
                      >
                        Tech Requirements and Browser Guide
                      </button>
                    </div>
                    {data.is_company === "Yes" && (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <button
                          type="button"
                          className={`${styles.modal_toggler} btn`}
                          style={{
                            background: data.p_color || "",
                            borderColor: data.p_color || "",
                          }}
                          onClick={() => setOrganizationInfoModal(true)}
                        >
                          Organization Information
                        </button>
                      </div>
                    )}
                    {data.is_contact === "Yes" && (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <button
                          type="button"
                          className={`${styles.modal_toggler} btn`}
                          style={{
                            background: data.p_color || "",
                            borderColor: data.p_color || "",
                          }}
                          onClick={() => setConnectUsModal(true)}
                        >
                          Connect with Us
                        </button>
                      </div>
                    )}
                    {data.is_chat === "Yes" && (
                      <>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <button
                            type="button"
                            className={`${styles.modal_toggler} btn`}
                            style={{
                              background: data.p_color || "",
                              borderColor: data.p_color || "",
                            }}
                            onClick={() => setLiveChatModal(true)}
                            // data-bs-toggle="modal"
                            // data-bs-target="#formmodal1"
                          >
                            Live Chat
                          </button>
                        </div>
                        {/* <div
                          className="modal fade"
                          id="formmodal1"
                          tabIndex="-1"
                          aria-labelledby="guidelinesModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h2
                                  className="modal-title fs-5"
                                  id="guidelinesModalLabel"
                                >
                                  Live chat
                                </h2>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <p>
                                  Please note that in case, if you will provide
                                  incorrect Demat/ Ledger Folio Number, then
                                  your question/ suggestion will not be
                                  addressed.
                                </p>
                                <form class="row g-4">
                                  <div className="col-sm-12 col-lg-6">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        name="name"
                                        id=""
                                        placeholder="Name"
                                        value={formData.name}
                                        onChange={(e) => handleInputChange(e)}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div class="col-sm-12 col-lg-6">
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        name="lf"
                                        id=""
                                        placeholder="Demat/ Ledger Folio Number"
                                        required
                                        value={formData.lf}
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                    </div>
                                  </div>
                                  <div class="col-sm-12 col-lg-12">
                                    <div class="form-group">
                                      <textarea
                                        name="question"
                                        id=""
                                        cols="30"
                                        rows="10"
                                        placeholder="Message"
                                        value={formData.question}
                                        onChange={(e) => handleInputChange(e)}
                                      ></textarea>
                                    </div>
                                  </div>
                                  <div class="col-sm-12 col-lg-12">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                      style={
                                        data && data.p_color !== ""
                                          ? {
                                              background: data && data.p_color,
                                              borderColor: data && data.p_color,
                                            }
                                          : {}
                                      }
                                      onClick={(e) => handleFormSubmit(e)}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </>
      ) : (
        <div className={Styles.wrapper}>
          <Container>
            <div className={Styles.innerContainer}>
              <div className={Styles.imageBox}>
                <img
                  src="/assets/images/404.png"
                  alt="404"
                  className="img-fluid"
                />
              </div>
              <h5>The meeting has ended or the meeting ID is incorrect.</h5>
              <div className={Styles.copyright}>
                <a
                  href="https://accuratesecurities.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Copyright &#169; 2024 Accurate Securities & Registry Private
                  Limited.
                </a>
              </div>
            </div>
          </Container>
        </div>
      )}

      <EntryModal show={modalShow} setShow={setModalShow} data={data} />
      <CustomHead
        title={data && data.org}
        description={"Accurate Securities"}
        logo={"%PUBLIC_URL%/fevicon.png"}
      />
      <TechRequirementsModal
        show={techRequirementModal}
        setShow={setTechRequirementModal}
      />
      <OrganizationInformationModal
        show={organizationInfoModal}
        setShow={setOrganizationInfoModal}
        data={data}
      />
      <ConnectUsModal
        show={connectUsModal}
        setShow={setConnectUsModal}
        data={data}
      />
      <LiveChatModal
        show={liveChatModal}
        setShow={setLiveChatModal}
        data={data}
        formData={formData}
        handleInputChange={handleInputChange}
        handleFormSubmit={handleFormSubmit}
      />
    </React.Fragment>
  );
};

export default RM;
